import React, { useEffect, useState } from 'react'
import * as Icon from 'react-bootstrap-icons';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import Index from '../../../../Assets/Images/Index';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ResponsivePagination from 'react-responsive-pagination';

import CurrencyFormat from 'react-currency-format';
import { dataValue } from '../../../../CommonReducer';
import { useDispatch } from 'react-redux';
import { Badge, Form, Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Pricing from '../Pricing/Pricing';

export default function StationProfile() {
    const [descriptionData, setDescription] = useState([])
    const [chargerList, setChargerList] = useState([])
    const [value, setValue] = useState({})
    const [edit, setEdit] = useState(false)
    const [btnLoading, setbtnLoading] = useState(false)
    const [type, setType] = useState("")
    const [loader, setloader] = useState(true)
    const [hasMore, sethasMore] = useState(true)
    const [hasMore2, sethasMore2] = useState(true)
    const [chargerPage, setchargerPage] = useState(1)
    const [chargerPage2, setchargerPage2] = useState(1)
    const [chargerPage3, setchargerPage3] = useState(1)
    const [totalCharger, settotalCharger] = useState(0)
    const [checkedCharger, setcheckedCharger] = useState([])
    const [assinedCharger, setAssinedCharger] = useState([])
    const [activeData, setActiveData] = useState("")
    const [pricinglist, setpricinglist] = useState()
    const [error, seterror] = useState(false)
    const [count, setCount] = useState(1)
    const [count2, setCount2] = useState(1)
    const [show, setShow] = useState({
        show: false,
        type: "",
        main: ""
    });
    const [loading, setloading] = useState(true)
    const dispatch = useDispatch()
    let limit = 20
    const handleClose = () => {
        setShow({
            show: false,
            type: "",
            main: ""
        })
        setType("")
        setValue({})
        setActiveData("")
        setchargerPage(1)
        // setchargerPage2(1)
        setcheckedCharger([])
        sethasMore(true)
        sethasMore2(true)
        settotalCharger(0)
        setEdit(false)
        seterror(false)
        setbtnLoading(false)
    }
    const getList = (param) => {
        console.log("param", param)
        Api.GetApi(`${endPoints.CHARGERDESCRIPTIONS}${param}`).then((res) => {
            console.log("res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (res.data.data.result && res.data.data.result.length > 0) {
                    const newData = res.data.data.result
                    setDescription(res.data.data.result)

                    let newCount = Math.ceil(parseInt(res.data.data?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount(items)
                    } else {
                        setCount(1)
                    }

                }
                handleClose()
            }
        })
    }
    const getChargerList = (id, param, type) => {
        Api.GetApi(`${endPoints.CHARGERLIST}/${type}/${id}${param}`).then((res) => {
            setloader(false)
            console.log("res CHARGERLIST", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (res.data.data.result && res.data.data.result.length > 0) {
                    const newData = res.data.data.result
                    if (chargerPage > 1) {
                        setChargerList(prevData => [...prevData, ...newData])
                    } else {
                        setChargerList(res.data.data.result)
                    }
                    settotalCharger(res.data.data.totalCount?.count)
                    if (newData.length === 0) {
                        sethasMore(false);
                    }
                } else {
                    setChargerList(res.data.data)
                }
            }
        })
    }

    // PRICING
    const getPricing = (param) => {
        Api.GetApi(`${endPoints.PRICING}${param}`).then(res => {
            console.log("pricinglist", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setloading(false)
                if (res.data.data.pricing && res.data.data.pricing.result && res.data.data.pricing.result.length > 0) {
                    const newData = res.data.data.pricing.result
                    setpricinglist(newData)
                }
                let newCount = Math.ceil(parseInt(res.data.data.pricing?.totalCount?.count) / limit)
                    if (newCount !== 0) {
                        let items = [];
                        for (let number = 1; number <= newCount; number++) {
                            items.push(number);
                        }
                        setCount2(items)
                    } else {
                        setCount2(1)
                    }


            }
        })
    }
    console.log("pricinglist", pricinglist)

    const updateFn = (api, url, body) => {
        api(url, body).then((res) => {
            console.log("res", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                if (typeof res.data.data == "string") {
                    Api.Alert(res.data.data, "success")
                }
                getList(`?page=${chargerPage2}`)
                getPricing(`?page=${chargerPage3}`)
            }
        })
    }

    const showModal = (type, main) => {
        setShow({
            show: true,
            type: type,
            main: main
        })
    }

    const editModal = (data, type, mainType) => {
        setEdit(true)
        setActiveData(data)
        setType(mainType)
        if (type == "station") {
            setloader(true)
            getChargerList(data._id, "", mainType)
        } else if (type == "add") {
            setValue({
                power: data.power,
                price: data.price,
                unit: data.unit
            })
        } else if (type == "delete") {

        } else {
            setValue({
                description: data?.description
            })
        }
        setShow({
            show: true,
            type: type,
            main: mainType
        })
    }

    const handleInputchange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const loadMore = () => {
        console.log("load more")
        if (chargerList.length >= totalCharger) {
            sethasMore(false);
            return;
        } else {
            sethasMore(true);
            setTimeout(() => {
                setchargerPage(prevPage => prevPage + 1);
            }, 1000);
        }
    };


    const handleCheck = (e) => {
        if (e.target.checked) {
            let arr = [...checkedCharger]
            arr.push(e.target.value)
            setcheckedCharger(arr)
        } else {
            let arr = checkedCharger.filter((x) => x !== e.target.value)
            setcheckedCharger(arr)
        }
    }
    const handleAssignedCheck = (e) => {
        if (e.target.checked) {
            let arr = [...assinedCharger]
            arr.push(e.target.value)
            setAssinedCharger(arr)
        } else {
            let arr = assinedCharger.filter((x) => x !== e.target.value)
            setAssinedCharger(arr)
        }
    }

    const submitCharger = (submitType) => {
        let data = {}
        if (submitType == "assigned") {
            data = {
                charger_id: assinedCharger
            }
            updateFn(Api.PostApi, `${endPoints.REMOVECHARGERLIST}/${type}/${activeData?._id}`, data)
        } else {
            data = {
                charger_id: checkedCharger
            }
            updateFn(Api.PostApi, `${endPoints.ADDCHARGERLIST}/${type}/${activeData?._id}`, data)
        }
    }

    const handleSubmit = () => {
        if (show.type == "desc") {
            if (!value.description) {
                seterror(true)
            } else {
                setbtnLoading(true)
                seterror(false)
                let api = ""
                let body = {}
                if (edit) {
                    api = Api.PutApi
                    body = {
                        id: activeData?._id,
                        description: value.description
                    }
                } else {
                    api = Api.PostApi
                    body = {
                        description: value.description
                    }
                }
                updateFn(api, endPoints.CHARGERDESCRIPTION, body)
            }
        }
    }

    const handlePriceSubmit = () => {
        if (show.main == "price" && show.type == "add") {
            if (!value.power || !value.price || !value.unit) {
                seterror(true)
            } else {
                setbtnLoading(true)
                seterror(false)
                let api = ""
                let body = {
                    power: value.power,
                    price: value.price,
                    unit: value.unit,
                }
                if (edit) {
                    api = Api.PutApi
                    updateFn(api, `${endPoints.PRICING}/${activeData?._id}`, body)
                } else {
                    api = Api.PostApi
                    updateFn(api, endPoints.PRICING, body)
                }
            }
        }
    }


    // loader

    // pagination

    const onPageChangeFunction = (number) => {
        setchargerPage2(number)
    }
    const onPageChangeFunction2 = (number) => {
        setchargerPage3(number)
    }


    const makeDefault = (data, type, main, mainType) => {
        setEdit(true)
        setActiveData(data)
        setType(mainType)
        setShow({
            show: true,
            type: type,
            main: main
        })
    }

    const yesConfirm = () => {
        setbtnLoading(true)
        updateFn(Api.GetApi, `${endPoints.SETDEFAULT}/${type}/${activeData?._id}`, "")
    }
    const yesDelete = () => {
        setbtnLoading(true)
        if (type == "price") {
            updateFn(Api.DeleteApi, `${endPoints.PRICING}/${activeData?._id}`, "")
        } else {
            updateFn(Api.DeleteApi, `${endPoints.CHARGERDESCRIPTION}/${activeData?._id}`, "")
        }
    }

    useEffect(() => {
        getList("")
        getPricing("")
    }, [])


    useEffect(() => {
        if (show.type == "station" && activeData) {
            getChargerList(activeData._id, `?page=${chargerPage}`, type)
        }
    }, [chargerPage]);

    useEffect(() => {
        getList(`?page=${chargerPage2}`)
    }, [chargerPage2]);
    useEffect(() => {
        getPricing(`?page=${chargerPage3}`)
    }, [chargerPage3]);

    console.log("chargerPage2: ", chargerPage2)
    // console.log("descriptionData: ", descriptionData)
    // console.log("checkedCharger: ", checkedCharger)

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Charging Station Profile</h1>
            </div>
            <div className="row profile">
                <div className="col-xxl-6">
                    <div className="card">
                        <div className="card-body  ">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className=" "><h5 className="card-title m-0">Description</h5></div>
                                <div className=" "> <button className="btn btn-sm btn-primary" onClick={() => showModal("desc", "description")}>+ Add new</button></div>
                            </div>

                            {descriptionData && descriptionData.length > 0 ?
                                <>
                                    <div className='description_list table-responsive'>
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">#</th> */}
                                                    <th className='sorting' scope="col">Description</th>
                                                    <th scope="col" className='text-end'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {descriptionData.map((opt, i) => {
                                                    let updInd = i + 1
                                                    return (
                                                        <tr key={i}>
                                                            {/* <td>{(params.page - 1) * 20 + updInd}</td> */}
                                                            <td width={"60%"} style={{ whiteSpace: "normal" }}>
                                                                {opt.is_default ?
                                                                    <Badge bg='success'>Default</Badge> : null
                                                                }
                                                                <p>{opt?.description || ""}</p>
                                                            </td>
                                                            <td className='text-end'>
                                                                {!opt.is_default ?
                                                                    <button className='btn btn-warning btn-sm me-2' onClick={() => makeDefault(opt, "station", "default", "description")}>Make default</button>
                                                                    : null
                                                                }
                                                                <button className='btn btn-success btn-sm me-2' onClick={() => editModal(opt, "station", "description")}>Stations</button>
                                                                <button className="btn btn-sm btn-primary me-2" onClick={() => editModal(opt, "desc", "description")}> <Icon.Pencil /> </button>
                                                                <button className="btn btn-sm btn-outline-danger" onClick={() => editModal(opt, "delete", "description")}> <Icon.Trash3Fill /> </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ResponsivePagination
                                        current={chargerPage2}
                                        total={count.length}
                                        onPageChange={onPageChangeFunction}
                                        // disabled={params.page === count.length}
                                        // maxWidth={500}
                                        className={"pagination "}
                                    />
                                </>
                                :
                                <div className='text-center py-3'>
                                    <h5>No result found</h5>
                                </div>
                            }


                        </div>
                    </div>

                </div>

                <div className="col-xxl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className=" "><h5 className="card-title m-0">Pricing</h5></div>
                                <div className=" "> <button className="btn btn-sm btn-primary" onClick={() => showModal("add", "price")}>+Add new</button></div>
                            </div>
                            {pricinglist && pricinglist.length > 0 ?
                                <>
                                    <div className='table-responsive'>
                                        <table className="table dataTable">
                                            <thead>
                                                <tr>
                                                    <th>Power</th>
                                                    <th>Price (RM)</th>
                                                    <th>Unit</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pricinglist && pricinglist.length > 0 && pricinglist.map((opt, indx) => {
                                                    return (
                                                        <tr className='' key={indx}>
                                                            <td width={"20%"}>
                                                                {opt.is_default ?
                                                                    <Badge bg='success'>Default</Badge> : null
                                                                }
                                                                <p>{opt.power}</p>
                                                            </td>
                                                            <td width={"20%"}>{opt.price}</td>
                                                            <td width={"20%"}>{opt.unit}</td>
                                                            <td className='text-end'>
                                                                {!opt.is_default ?
                                                                    <button className='btn btn-warning btn-sm me-2' onClick={() => makeDefault(opt, "station", "default", "price")}>Make default</button>
                                                                    : null
                                                                }
                                                                <button className='btn btn-success btn-sm me-2' onClick={() => editModal(opt, "station", "price")}>Stations</button>
                                                                <button className={`btn btn-text btn-sm btn-primary`} onClick={() => editModal(opt, "add", "price")}> <Icon.Pencil /> </button>
                                                                <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => editModal(opt, "delete", "price")}> <Icon.Trash3Fill /> </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <ResponsivePagination
                                        current={chargerPage3}
                                        total={count2.length}
                                        onPageChange={onPageChangeFunction2}
                                        disabled={chargerPage3 === count2.length}
                                        // maxWidth={500}
                                        className={"pagination "}
                                    />

                                </>
                                :
                                <div className='text-center py-3'>
                                    <h5>No result found</h5>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
            <Modal show={show.show} onHide={handleClose} size={show.main == "default" ? "md" : 'lg'} centered>
                {show.main !== "default" && show.type !== "delete" ?
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {show.type == "desc" ?
                                "Add description"
                                :
                                show.type == "station" ? "Charging stations"
                                    :
                                    show.main == "price" && show.type == "add" ?
                                        "Pricing"
                                        :
                                        ""
                            }
                        </Modal.Title>
                    </Modal.Header>
                    : null
                }
                <Modal.Body className='py-4'>
                    {show.main == "default" && show.type !== "delete" ?
                        <>
                            <div className='text-center my-4'>
                                <h4> Are you sure?</h4>
                                <h5>You want to make it default.</h5>
                            </div>
                            <Modal.Footer className='justify-content-center'>
                                <button className='btn btn-primary' disabled={btnLoading} onClick={btnLoading ? null : yesConfirm}>
                                    {btnLoading ? "Please wait" : "Yes"}
                                </button>
                                <button className='btn btn-danger' onClick={handleClose}>Dismiss</button>
                            </Modal.Footer>
                        </>
                        :
                        show.main !== "default" && show.type == "delete" ?
                            <>
                                <div className='text-center my-4'>
                                    <h4> Are you sure?</h4>
                                    <h5>You want to delete.</h5>
                                </div>
                                <Modal.Footer className='justify-content-center'>
                                    <button className='btn btn-primary' disabled={btnLoading} onClick={btnLoading ? null : yesDelete}>
                                        {btnLoading ? "Please wait" : "Yes"}
                                    </button>
                                    <button className='btn btn-danger' onClick={handleClose}>Dismiss</button>
                                </Modal.Footer>
                            </>
                            :
                            <>
                                {show.type == "desc" ?
                                    <>
                                        <label htmlFor="" className="form-label">Enter description*</label>
                                        <textarea name='description' className="form-control" onChange={handleInputchange} value={value?.description} rows={10} />
                                        {error && !value.description ?
                                            <p style={{ color: "red" }}>Please enter description</p> : null
                                        }
                                        <Modal.Footer className='mt-4'>
                                            <button className='btn btn-primary' disabled={btnLoading} onClick={btnLoading ? null : handleSubmit}>
                                                {btnLoading ? "Please wait" : "Submit"}
                                            </button>
                                            <button className='btn btn-danger' onClick={handleClose}>Dismiss</button>
                                        </Modal.Footer>
                                    </>
                                    : null
                                }
                                {show.main == "price" && show.type == "add" ?
                                    <>
                                        <div className='mb-2'>
                                            <label htmlFor="" className="form-label">Power</label>
                                            <input placeholder="" type="text" name="power" className="form-control" onChange={handleInputchange} value={value?.power} />
                                            {error && !value.power ?
                                                <p style={{ color: "red" }}>Please enter power</p> : null
                                            }
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="" className="form-label">Price (RM)</label>
                                            <input placeholder="" type="text" name="price" className="form-control" onChange={handleInputchange} value={value?.price} />
                                            {error && !value.price ?
                                                <p style={{ color: "red" }}>Please enter price</p> : null
                                            }
                                        </div>
                                        <div className='mb-5'>
                                            <label htmlFor="" className="form-label">Unit</label>
                                            <input placeholder="" type="text" name="unit" className="form-control" onChange={handleInputchange} value={value?.unit} />
                                            {error && !value.unit ?
                                                <p style={{ color: "red" }}>Please enter unit</p> : null
                                            }
                                        </div>
                                        <Modal.Footer>
                                            <button className='btn btn-primary' disabled={btnLoading} onClick={btnLoading ? null : handlePriceSubmit}>
                                                {btnLoading ? "Please wait" : "Submit"}
                                            </button>
                                            <button className='btn btn-danger' onClick={handleClose}>Dismiss</button>
                                        </Modal.Footer>
                                    </>
                                    : null
                                }
                                {show.type == "station" ?
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h6>Assigned Chargers</h6>
                                            {loader ? "Loading..." : activeData.assign_charger.length > 0 ?
                                                <>
                                                    <div className='chargers_assignmentOverFlow'>
                                                        <InfiniteScroll
                                                            dataLength={activeData.assign_charger.length}
                                                            next={loadMore}
                                                            hasMore={hasMore}
                                                            loader={<p><strong>Loading...</strong></p>}
                                                            height={340}
                                                            >
                                                            {activeData.assign_charger.map((opt, i) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={opt?._id}
                                                                        type={"checkbox"}
                                                                        id={opt?._id}
                                                                        label={opt?.charging_station?.endpoint || ""}
                                                                        value={opt?.charging_station?._id}
                                                                        onChange={handleAssignedCheck}
                                                                    />
                                                                )
                                                            })}
                                                        </InfiniteScroll>
                                                        {assinedCharger.length ?
                                                            <Modal.Footer className='justify-content-start ps-0'>
                                                                <button className='btn btn-danger' disabled={btnLoading} onClick={btnLoading ? null : () => submitCharger("assigned")}>
                                                                    {btnLoading ? "Please wait" : "Remove"}
                                                                </button>
                                                            </Modal.Footer>
                                                            : null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <h6 className='py-3'>No charger assigned</h6>
                                            }
                                        </div>
                                        <div className='col-md-6'>
                                            <h6>Available Chargers</h6>
                                            {loader ? "Loading..." : chargerList.length > 0 ?
                                                <>
                                                    <div className='chargers_assignmentOverFlow'>
                                                        <InfiniteScroll
                                                            dataLength={chargerList.length}
                                                            next={loadMore}
                                                            hasMore={hasMore}
                                                            loader={<p><strong>Loading...</strong></p>}
                                                            height={340}
                                                        >
                                                            {chargerList.map((opt, i) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={opt?._id}
                                                                        type={"checkbox"}
                                                                        id={opt?._id}
                                                                        label={opt?.endpoint || ""}
                                                                        value={opt?._id}
                                                                        onChange={handleCheck}
                                                                    />
                                                                )
                                                            })}
                                                        </InfiniteScroll>
                                                        {checkedCharger.length ?
                                                            <Modal.Footer>
                                                                <button className='btn btn-primary' disabled={btnLoading} onClick={btnLoading ? null : () => submitCharger("available")}>
                                                                    {btnLoading ? "Please wait" : "Assign"}
                                                                </button>
                                                            </Modal.Footer>
                                                            : null
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <h6 className='py-3'>No charger available</h6>
                                            }
                                        </div>
                                    </div>
                                    : null
                                }
                            </>
                    }
                </Modal.Body>
            </Modal>

        </main>
    )
}
