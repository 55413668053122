import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';

export default function Pricing() {
    const [loading, setloading] = useState(true)
    const [pricingBox, setpricingBox] = useState({})
    const [pricinglist, setpricinglist] = useState()
    const [editProfileTab, seteditProfileTab] = useState(false)
    const [error, seterror] = useState(false)
    const [disableAddBtn, setdisableAddBtn] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setmodalType({})
        setPopData({})
    };

    const [popData, setPopData] = useState({})
    const [modalType, setmodalType] = useState({})

    // PRICING
    const getPricing = () => {
        Api.GetApi(endPoints.PRICING, "").then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setloading(false)
                setpricinglist(res.data?.data?.pricing?.result)
            }
        })
    }

    const handlePriceBox = (key1, key2) => {
        setpricingBox(
            {
                power: key1,
                price: key2,
            }
        )
    }


    const toggleEditState = (data) => {

        let arrr = []
        pricinglist?.map((opt) => {
            if (opt._id === data?._id) {
                handlePriceBox(opt.power, opt.price)
                arrr.push({ ...opt, active: !opt.active })

            } else {
                arrr.push({ ...opt, active: false })
            }

        })
        setpricinglist(arrr)
        // seteditProfileTab(!editProfileTab)
        // getProfileDetail("", Api.GetApi)

        setpricingBox({ ...pricingBox, 'power': data?.power, 'price': data?.price, 'unit': data?.unit })

    }

    const deleteNewField = (data) => {
        toggleEditState(data)
        setdisableAddBtn(false)
        let arr = []
        pricinglist?.map((opt) => {
            arr.push({ ...opt })
        })
        arr.splice(0, 1)

        setpricinglist(arr)

    }
    const addNew = () => {
        setpricingBox({ power: "", price: "", unit: "" })
        let arr = [{
            price: "",
            power: "",
            unit: "",
            active: true,
            isNew: true,
        }
        ]

        pricinglist?.map((opt) => {
            arr.push({ ...opt, active: false })
        })
        console.log("arr", arr)
        setpricinglist(arr)
        setdisableAddBtn(true)
    }
    const handleInputchange = (e) => {
        setpricingBox({ ...pricingBox, [e.target.name]: e.target.value })

    }

    const updatePricing = (id) => {
        if (pricingBox.power === "" || pricingBox.unit === "" || pricingBox.price == "") {
            seterror(true)
        } else if (pricingBox.price < 0) {
            seterror(true)
        }
        else {
            seterror(false)

            let obj = {
                power: pricingBox.power,
                price: pricingBox.price,
                unit: pricingBox.unit,
            }
            Api.PutApi(`${endPoints.PRICING}/${id}`, obj).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert("Updated sucessfully", "success")
                    setloading(false)
                    getPricing()

                }
            })
        }
    }

    const deletePricing = (id) => {
        Api.DeleteApi(`${endPoints.PRICING}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                Api.Alert("Removed sucessfully", "success")
                setloading(false)
                getPricing()
                handleClose()
            }
        })

    }
    const updateNewPricing = () => {
        if (pricingBox.power === "" || pricingBox.unit === "" || pricingBox.price == "") {
            seterror(true)
        } else if (pricingBox.price < 0) {
            seterror(true)
        }
        else {
            seterror(false)
            let obj = {
                power: pricingBox.power,
                price: pricingBox.price,
                unit: pricingBox.unit,
            }

            Api.PostApi(endPoints.PRICING, obj).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert("Updated sucessfully", "success")
                    setloading(false)
                    getPricing()
                    setdisableAddBtn(false)
                }
            })
        }

    }
    const showModal = (data, type) => {
        setPopData(data)
        setmodalType(type)
        setShow(true)
    }
    console.log("pricinglist", pricinglist)
    console.log("pricingBox", pricingBox)

    useEffect(() => {
        getPricing()
    }, [])

    return (
        <main id="main" className="main">
            <div className="row">
                <div className="col-12 col-lg-8 col-xxl-7">
                    <div className="pagetitle d-flex justify-content-between align-items-center">
                        <h1>Pricing</h1>
                        <button className={disableAddBtn ? "btn btn-primary disabled" : "btn btn-primary"} onClick={addNew}>Add New</button>
                    </div>
                </div>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="row">
                    <div className="col-12 col-lg-8 col-xxl-7">
                        <div className="card">
                            <div className="card-body py-3">
                                {/* <h5 className="card-title"> </h5> */}
                                <div className='list-group list-group-flush '>
                                    {pricinglist && pricinglist?.length > 0 ? <>

                                        {pricinglist && pricinglist?.length > 0 && pricinglist?.map((data, indx) => {
                                            return (
                                                <div className=' list-group-item d-flex row' key={indx}>
                                                    <div className="col-lg-3 col-md-3 my-1">
                                                        <label className="form-label" htmlFor="">Power</label>
                                                        <input placeholder="" type="text" name="power" className="form-control" value={data?.active ? pricingBox?.power : data?.power || "--"} onChange={handleInputchange} readOnly={data?.active ? false : true} maxLength="20" />
                                                        {data?.active && pricingBox.power === "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add Power</div> : null}
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 my-1">
                                                        <label className="form-label" htmlFor="">Price (RM)</label>
                                                        <input placeholder="" type="number" name="price" value={data?.active ? pricingBox?.price : data?.price || "--"} onChange={handleInputchange} readOnly={data?.active ? false : true} className="form-control" maxLength="20" />
                                                        {data?.active && pricingBox.price === "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add Price</div> : null}
                                                        {data?.active && pricingBox.price < 0 && error === true ? <div className='invalid-feedback mb-2 d-block'>Price can not be negative</div> : null}
                                                    </div>
                                                    <div className="col-lg-3 col-md-3 my-1">
                                                        <label className="form-label" htmlFor="">Unit</label>
                                                        <input placeholder="" type="text" name="unit" value={data?.active ? pricingBox?.unit : data?.unit || "--"} onChange={handleInputchange} readOnly={data?.active ? false : true} className="form-control" maxLength="20" />
                                                        {data?.active && pricingBox.unit === "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add unit</div> : null}

                                                    </div>
                                                    <div className="col-lg-3 col-md-3 my-1">
                                                        <div>
                                                            <label className="form-label" htmlFor="">&nbsp;</label>
                                                        </div>
                                                        <div className="">
                                                            {data?.active ?
                                                                <>
                                                                    {data?.isNew ?
                                                                        <>
                                                                            <button type="submit" className='btn btn-sm btn-primary ms-1' onClick={() => updateNewPricing()}
                                                                            >Add</button>
                                                                            <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => deleteNewField(data)}><Icon.TrashFill /> </button>
                                                                        </> :
                                                                        <>
                                                                            <button className="btn btn-sm btn-outline-danger" onClick={() => toggleEditState(data)}> <Icon.XLg /> </button>
                                                                            <button type="submit" className='btn btn-sm btn-primary ms-1' onClick={() => updatePricing(data?._id)}>Update</button>
                                                                        </>
                                                                    }
                                                                </> :
                                                                <>
                                                                    <button className={`btn btn-text btn-sm btn-primary`} onClick={() => toggleEditState(data)}> <Icon.Pencil /> </button>
                                                                    <button className="btn btn-sm btn-outline-danger ms-2" onClick={() => showModal(data?._id, "deletePricingModal")}> <Icon.Trash3Fill /> </button>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                    </> :
                                        <div className='text-center py-3'>
                                            <h3>No result found </h3>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal show={show} centered onHide={handleClose}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>

                    {modalType == "deletePricingModal" && <>
                        <div className='text-center mb-4'>
                            <h4> Are you sure?</h4>
                            <h5>You want to remove the selected Pricing.</h5>
                        </div>
                        <div className='text-center mt-2'>
                            <button className='btn btn-danger btn-sm me-3' onClick={() => deletePricing(popData)}> Confirm</button>
                            <button className='btn btn-outline-danger btn-sm' onClick={handleClose} > Cancel</button>
                        </div>
                    </>}
                </Modal.Body>

            </Modal>
        </main>
    )
}
