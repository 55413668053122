import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import * as Api from "../../../../Config/Apis"
import endPoints from "../../../../Config/endPoints";
import * as Icon from 'react-bootstrap-icons';
import ResponsivePagination from 'react-responsive-pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
import CurrencyFormat from 'react-currency-format';

export default function Bonus() {
    const searchParams = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    const location = useLocation()

    const [loading, setloading] = useState(true)
    const [error, seterror] = useState(false)
    const [bonus, setbonus] = useState()
    const [logs, setlogs] = useState()
    const [active, setactive] = useState(false)
    const [editBonus, seteditBonus] = useState({
        is_active: false,
        value: "",
        code: "",
    })

    let limit = 20
    const [params, setParams] = useState({
        // sort_key: searchParams.get("sort_key") || "",
        // sort_value: searchParams.get("sort_value") || "asc",
        search: searchParams.get("search") || "",
        page: searchParams.get("page") || 1,
    })
    const [count, setCount] = useState(1)
    const [searchProgress, setsearchProgress] = useState(false)
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const [search, setsearch] = useState(searchParams.get("search") || "")
    const searchUser = (e) => {
        setsearch(e.target.value)
        if (e.target.value == "") {
            setParams({ ...params, 'page': 1, 'search': "" })
            deleteparam("search")
        } else {
            setparam("search", e.target.value)
        }
    }
    useEffect(() => {
        // deleteparam("page")
        const delayDebounceFn = setTimeout(() => {
            if (search != "") {
                setsearchProgress(true)
                setParams({ ...params, page: 1, search: search })
                setparam('search', search)
            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const onSelectChange = (e) => {
        setParams({ ...params, [e.target.name]: e.target.value })
        if (e.target.name == "sort_value") {
            if (params.sort_key != "") {
                setparam("sort_value", e.target.value)
            } else {
                deleteparam("sort_value")
            }
        } else {
            setparam([e.target.name], e.target.value)
            setparam("sort_value", params.sort_value)
        }
    }
    const onPageChangeFunction = (number) => {
        if (number !== parseInt(params.page)) {
            setParams({ ...params, page: number })
            setparam("page", number)
            setloading(true)
        } else {
            setparam("page", number)
            setParams({ ...params, page: number })
        }
    }
    const clearFilter = () => {
        setParams({
            ...params,
            sort_key: "",
            sort_value: "asc",
        })
        deleteparam("sort_key")
        deleteparam("sort_value")
    }
    const clearSearch = () => {
        setParams({
            ...params,
            search: "",
        })
        setsearch("")
        deleteparam("search")
        setsearchProgress(true)
    }
    // GETSIGNUPBONUS
    const getSignupbonus = () => {
        Api.GetApi(endPoints.GETSIGNUPBONUS, "").then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                setloading(false)
            } else {
                setloading(false)
                setbonus(res.data?.data)
            }
        })
    }
    // GETSIGNUPLOGS
    const getSignuplogs = (param) => {
        Api.GetApi(`${endPoints.GETSIGNUPLOGS}${param}`).then(res => {
            setloading(false)
            setsearchProgress(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloading(false)
            } else {
                // setloading(false)
                setlogs(res.data?.data[0]?.result)
                // setC
                let newCount = Math.ceil(parseInt(res.data?.data[0]?.totalCount?.count) / limit)
                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }



            }
        })
    }

    const toggleEditState = (type) => {
        setactive(!active)
        if (type == "close") {
            seteditBonus({
                code: "",
                value: "",
            })

        } else {
            seteditBonus({
                code: bonus?.code,
                value: bonus?.value,
            })
        }
    }


    const toggleActiveState = (id) => {
        Api.GetApi(`${endPoints.TOGGLESIGNUPBONUS}/${id}`).then(res => {
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                // setloading(false)
            } else {
                Api.Alert("Updated sucessfully", "success")
                getSignupbonus()
            }
        })

    }

    const updateNewBonus = () => {
        if (editBonus.code === "" || editBonus.value == "") {
            seterror(true)
        } else if (editBonus.code.length < 6) {
            seterror(true)

        } else if (editBonus.value < 0) {
            seterror(true)
        } else {
            seterror(false)
            let obj = {
                code: editBonus.code,
                value: editBonus.value
            }
            Api.PutApi(`${endPoints.UPDATESIGNUPBONUS}/${bonus?._id}`, obj).then(res => {
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                    setloading(false)
                } else {
                    Api.Alert("Updated sucessfully", "success")
                    setloading(false)
                    getSignupbonus()
                    toggleEditState("close")
                }
            })
        }
    }

    const handleInputchange = (e) => {

        seteditBonus({ ...editBonus, [e.target.name]: e.target.value.replace(/^\s+/, '') })
    }



    useEffect(() => {
        getSignupbonus()
        // getSignuplogs()
    }, [])


    useEffect(() => {
        // if (!searchParams.get("sort_key")) {
        //     clearFilter()
        // }
        // if (!searchParams.get("search")) {
        //     console.log("search lebnth1 true", searchParams.get("search"))
        //     // clearSearch()
        // }
        // if (searchParams.get("page") && searchParams.get("page") != params.page) {
        //     setParams({ ...params, page: searchParams.get("page") })
        // }
        console.log("searchParams.get", searchParams.get("sort_key"))
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        getSignuplogs(location.search)
    }, [params])




    return (
        <main id="main" className="main">
            <div className="row">
                <div className="col-12 col-lg-8 col-xxl-7">
                    <div className="pagetitle d-flex justify-content-between align-items-center">
                        <h1>Voucher Bonus</h1>

                    </div>
                </div>
            </div>
            {loading ?
                <div className="loader-line"></div> :
                <div className="row">
                    <div className="col-12 col-lg-8 col-xxl-12">
                        <div className="card">
                            <div className="card-body py-3">
                            <div className="row">
                            <div className="col-12 col-lg-8  ">
                                <div className='list-group list-group-flush '>
                                    <div className=' list-group-item d-flex row'>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label fw-bold" htmlFor="">Code</label>
                                            {!active ?
                                                <div className='form-control border-0 px-0'>{bonus?.code}</div> :
                                                <>
                                                    <input placeholder="Enter Code" type="text" name="code" className="form-control" onChange={handleInputchange} value={editBonus?.code} maxLength="40" />
                                                    {editBonus.code === "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add code</div> : null}
                                                    {editBonus.code.length < 6 && error === true ? <div className='invalid-feedback mb-2 d-block'>Enter at least 6 characters</div> : null}
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label fw-bold" htmlFor="">Value{!active ? "" : "(RM)" }</label>
                                            {!active ?
                                                <div className='form-control border-0 px-0'> 
                                                    <CurrencyFormat value={parseFloat(bonus?.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'RM '} />
                                                </div> :
                                                <>
                                                    <input placeholder="Enter Value" type="number" name="value" className="form-control"
                                                        onChange={handleInputchange} value={editBonus?.value}
                                                        min="0" maxLength="20" />
                                                        
                                                    {editBonus.value === "" && error === true ? <div className='invalid-feedback mb-2 d-block'>Please add value</div> : null}
                                                    {editBonus.value < 0 && error === true ? <div className='invalid-feedback mb-2 d-block'>Enter a value above 0</div> : null}
                                                </>
                                            }
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <label className="form-label fw-bold" htmlFor="">Status</label>
                                            <div className="py-2" >
                                                <Form className='d-flex align-items-center '>
                                                    <Form.Check
                                                        className='mb-0'
                                                        type="switch"
                                                        // id={data?._id?._id}
                                                        label={bonus?.is_active ? "Enabled" : "Disabled"}
                                                        checked={bonus?.is_active}
                                                        // value={data?._id?._id}
                                                        onChange={() => toggleActiveState(bonus?._id)}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 my-1">
                                            <div>
                                                <label className="form-label" htmlFor="">&nbsp;</label>
                                            </div>
                                            {!active ?
                                                <button className="btn btn-sm btn-text btn-outline-primary" onClick={() => toggleEditState("edit")}  > <Icon.Pencil /> </button>
                                                :
                                                <>
                                                    <button className="btn btn-sm btn-outline-danger " onClick={() => toggleEditState("close")}>
                                                        <Icon.XLg />
                                                    </button>
                                                    <button type="submit" className='btn btn-sm btn-primary ms-1' onClick={updateNewBonus}>Update</button>
                                                </>
                                            }

                                        </div>
                                    </div>

                                </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body py-3">
                                <h5 className='card-title pb-0 mb-3'>Bonus Logs</h5>
                                <div className="d-flex mb-0 mb-md-4 justify-content-between align-items-center userdetailTopRow ">
                                    <div className='userdetailTopRow__left col-12 col-md-5'>
                                        <div className="d-flex align-items-start align-items-sm-center  mb-3 mb-md-2 flex-column flex-sm-row  " >
                                            <div className="search-bar pe-1  ">
                                                <div className="search-form d-flex align-items-center" >
                                                    <input type="text" className='form-control'
                                                        name="search" placeholder="Search by name/email/phone" value={search} title="Enter search keyword" onChange={searchUser} autoComplete="off" disabled={searchProgress} />
                                                    <span className="searchBarIcon" title="Search"><Icon.Search /></span>
                                                </div>
                                            </div>
                                            {search !== "" ?
                                                <button type="button" disabled={searchProgress} className="textClear nowrap " style={{ opacity: searchProgress ? "0" : "1" }} onClick={clearSearch}>Clear Search</button> : null
                                            }
                                        </div>
                                    </div>

                                </div>

                                {logs && logs.length > 0 ?
                                    <>
                                        <div className="table-responsive">
                                            <table className='table dataTable'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Code</th>
                                                        <th>Value</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logs && logs.length > 0 && logs.map((data, indx) => {
                                                        return (
                                                            <tr key={indx}>
                                                                <td>{data?._id.name || "--"}</td>
                                                                <td>{data?._id.email || "--"}</td>
                                                                <td>{data?._id.phone || "--"}</td>
                                                                <td>{data?._id.code || "--"}</td>
                                                                <td>{data?._id.value || "--"}</td>
                                                                <td></td>
                                                            </tr>)
                                                    })}

                                                </tbody>
                                            </table>

                                        </div>
                                        <div style={{ maxWidth: "500px" }}>
                                            {searchProgress ?
                                                <div className='loadingTd' style={{ maxWidth: "100px" }}><div className='bar'></div>
                                                </div> :
                                                <ResponsivePagination
                                                    current={parseFloat(searchParams.get("page"))}
                                                    total={count.length}
                                                    onPageChange={onPageChangeFunction}
                                                    disabled={params.page === count.length}
                                                    // maxWidth={500}
                                                    className={"pagination "}
                                                />
                                            }
                                        </div></>
                                    :


                                    <div className='text-center py-5'>
                                        <h3>No result found</h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }


        </main >
    )
}
